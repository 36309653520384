import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: () =>
            import ('../views/index.vue'),
        children: [{
                path: '/',
                name: 'home',
                component: () =>
                    import ('../views/Home.vue'),
                meta: {
                    index: 0
                }
            },
            {
                path: '/about',
                name: 'about',
                component: () =>
                    import ('../views/About.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/list',
                name: 'list',
                component: () =>
                    import ('../views/list.vue'),
                meta: {
                    index: 2
                }
            },
            {
                path: '/detail',
                name: 'detail',
                component: () =>
                    import ('../views/detail.vue'),
                meta: {
                    index: 2
                }
            },
            {
                path: '/listPage',
                name: 'listPage',
                component: () =>
                    import ('../views/listPage.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/party',
                name: 'party',
                component: () =>
                    import ('../views/party.vue'),
                meta: {
                    index: 3
                }
            },
            {
                path: '/business',
                name: 'business',
                component: () =>
                    import ('../views/business.vue'),
                meta: {
                    index: 4
                }
            },
            {
                path: '/competence',
                name: 'competence',
                component: () =>
                    import ('../views/competence.vue'),
                meta: {
                    index: 7
                }
            },
            {
                path: '/job',
                name: 'job',
                component: () =>
                    import ('../views/job.vue'),
                meta: {
                    index: 5
                }
            },
            {
                path: '/learning',
                name: 'learning',
                component: () =>
                    import ('../views/learning.vue'),
                meta: {
                    index: 6
                }
            },
            {
                path: '/specail',
                name: 'specail',
                component: () =>
                    import ('../views/specail.vue'),
                meta: {
                    index: 8
                }
            },
            {
                path: '/culture',
                name: 'culture',
                component: () =>
                    import ('../views/culture.vue'),
                meta: {
                    index: 9
                }
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router